import { useQuery } from "@tanstack/react-query";
import { apiGet } from "../helpers/request.helpers";
import { useAuth } from "../AuthContext";
import { useIsAdminUser } from "../helpers/server.helpers";

export type OrganizationListOption = {
  ID: number;
  Name: string;
  ContactInfo: string;
  CreatedBy: number;
  CreationTime: string;
  SaveDailyStats: boolean;
  Active: boolean;
  DefaultLanguage: string;
  Environment: string;
  SchoolCount: number;
  LicenseCount: number;
  SimulatorCount: number;
};

export function useOrganizationList() {
  const { isAuthenticated } = useAuth();
  const isAdminUser = useIsAdminUser();
  return useQuery({
    queryKey: ["organization-list"],
    queryFn: async () => {
      const json = await apiGet("/admin/list-organizations", {});
      const list: OrganizationListOption[] = json.Organizations;
      const map = new Map<number, OrganizationListOption>();
      list.forEach((org) => {
        map.set(org.ID, org);
      });
      return { list, map };
    },
    staleTime: Infinity,
    enabled: isAdminUser && !!isAuthenticated,
  });
}
